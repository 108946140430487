import React from 'react'
import * as styles from './404.module.scss'

function NotFound() {
  return (
    <div className={styles.notFound}>
      <h3>Sorry, page not found!</h3>
    </div>
  )
}

export default NotFound
